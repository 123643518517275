import React from 'react';

const Ad = () => {
  return (
    <div className="col-md-12">
      <ins className="adsbygoogle"
        style={{ 'display': 'block' }}
        data-ad-client="ca-pub-1875196661090598"
        data-ad-slot="3171976425"
        data-ad-format="auto"
        data-ad-test="on">
      </ins>
    </div>
  );
};

export default Ad;