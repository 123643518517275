import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MoneyField from './MoneyField';
import InputButton from './InputButton';
import StoreCategorySelect from './StoreCategorySelect';
import { calcEbayFee, calcEbayRate, calcPaypalFee, DEFAULT_CATEGORY } from './feeCalculator';

const roundResult = input => parseFloat(input).toFixed(2);

class CalculationResults extends Component {
  static propTypes = {
    buyerShipping: PropTypes.string,
    isTopRated: PropTypes.bool,
    onTopRatedChange: PropTypes.func,
    purchasePrice: PropTypes.string,
    sellerShipping: PropTypes.string,
    sellPrice: PropTypes.string,
  }

  state = {
    isStore: false,
    selectedCategory: null,
  }

  onCategoryChange  = event => this.setState({selectedCategory: event.target.value});

  onIsStoreChange = () => {
    this.setState(prevState => {
      const isStore = !prevState.isStore;
      const selectedCategory = (isStore) ? DEFAULT_CATEGORY : null;
      return { isStore, selectedCategory };
    });
  }

  floatCalcProps = () => {
    let { sellPrice, buyerShipping, sellerShipping, purchasePrice } = this.props;
    sellPrice = parseFloat(sellPrice) || 0;
    buyerShipping = parseFloat(buyerShipping) || 0;
    sellerShipping = parseFloat(sellerShipping) || 0;
    purchasePrice = parseFloat(purchasePrice) || 0;  
    return { sellPrice, buyerShipping, sellerShipping, purchasePrice };  
  }

  render() {
    const { isTopRated, onTopRatedChange } = this.props;
    const { sellPrice, buyerShipping, sellerShipping, purchasePrice } = this.floatCalcProps();

    const ebayRate = calcEbayRate(this.state.selectedCategory, isTopRated);
    const ebayFee = calcEbayFee(sellPrice, buyerShipping, ebayRate);
    const paypalFee = calcPaypalFee(sellPrice, buyerShipping);
    const inPocket = sellPrice + buyerShipping - sellerShipping - ebayFee - paypalFee;
    const profit = inPocket - purchasePrice;

    const ebayRateText = `${(ebayRate * 100).toFixed(1)}%`
    const profitText = profit >= 0 ? 'Profit' : 'Loss';

    return (
      <div>
        <MoneyField label={`Ebay fee (${ebayRateText})`} value={roundResult(ebayFee)} isDisabled>
          <InputButton onClick={onTopRatedChange} label="TRS" noRadius />
          <InputButton onClick={this.onIsStoreChange} label="Store" type="success" />
        </MoneyField>
        { this.state.isStore && <StoreCategorySelect onChange={this.onCategoryChange}/> }
        <MoneyField label='Paypal fee' value={roundResult(paypalFee)} isDisabled />
        <MoneyField label={profitText} value={roundResult(profit)} isDisabled={true} isProfitLabel />
      </div>
    );
  }
};

export default CalculationResults;