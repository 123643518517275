import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { StoreRates } from './feeCalculator';

class StoreCategorySelect extends Component {
  static propTypes = {
    onChange: PropTypes.func,
  }
  static defaultProps = {
    onChange: () => {},
  }
  state = {
    isStore: false,
  }
  onIsStoreChange = () => {
    this.setState((prevState, props) => {
      return { isStore: !prevState.isStore };
    });
  }
  render() {
    return (
      <FormGroup>
        <Col sm={4} componentClass={ControlLabel}>Store Categories</Col>
        <Col sm={4}>
          <FormControl componentClass="select" onChange={this.props.onChange}>
            { StoreRates.map(cat => <option key={cat[0]} value={cat[0]}>{cat[0]}</option>)}
          </FormControl>
        </Col>
      </FormGroup>
    )

  }
};

export default StoreCategorySelect;