import React, { Component } from 'react';
import { Form, Grid } from 'react-bootstrap';
import MoneyField from './MoneyField';
import CalculationResults from './CalculationResults';
import Ad from './Ad';

class App extends Component {
  state = {
    purchasePrice: '',
    sellPrice: '',
    buyerShipping: '',
    sellerShipping: '',
    isTopRated: false
  }

  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  purchasePriceChange = event => this.setState({purchasePrice: event.target.value});
  sellPriceChange = event => this.setState({sellPrice: event.target.value});
  sellerShippingChange = event => this.setState({sellerShipping: event.target.value});
  buyerShippingChange = event => this.setState({buyerShipping: event.target.value});

  topRatedChange = () => {
    this.setState(prevState => ({isTopRated: !prevState.isTopRated}));
  }

  render() {
    const { sellPrice, buyerShipping, sellerShipping, purchasePrice } = this.state;
    const isCalcReady = !!(purchasePrice || sellPrice);

    return (
      <Grid>
        <h1>ebay profit calculator</h1>
        <Form horizontal>
          <MoneyField label='Purchase price' value={purchasePrice} onChange={this.purchasePriceChange} />
          <MoneyField label='Selling price' value={sellPrice} onChange={this.sellPriceChange} />
          <MoneyField label='Shipping paid by buyer' value={buyerShipping} onChange={this.buyerShippingChange} />
          <MoneyField label='Shipping paid by seller' value={sellerShipping} onChange={this.sellerShippingChange} />
          { isCalcReady && <CalculationResults onTopRatedChange={this.topRatedChange} {...this.state}  /> }
        </Form>
        <br />
        <small>Not affiliated with or endorsed by eBay or PayPal. While effort will be made to update, calculation accuracy is not guaranteed.</small>
        <Ad />
      </Grid>
    );
  }
}

export default App;
