export const Multipliers = {
  topRatedMultiplier: 0.08,
  ebayMultiplier: 0.1,
  paypalBase: 0.3,
  paypalMultiplier: 0.029,
}

export const DEFAULT_CATEGORY = 'All Other Categories';

export const StoreRates = [
  [DEFAULT_CATEGORY, .0915],
  ['Coins & Paper Money', .0615],
  ['Musical Instruments & Gear', .0715],
  ['Motors Parts/Tools/Supplies', .0815],
  ['Computers/Tablets/Networking/Game Consoles', .04],
  ['Consumer Electronics, Cameras & Photo', .0615],
]

export const calcEbayRate = (category, isTopRated) =>  {
  const baseRate = (category) ? StoreRates.find(cat => cat[0] === category)[1] : Multipliers.ebayMultiplier;
  return isTopRated ? (baseRate * Multipliers.topRatedMultiplier * 10) : baseRate;
}


export const calcEbayFee = (sellPrice, buyerShipping, rate) => {
  const ebayFeeOnItem = sellPrice * rate;
  const ebayFeeOnShipping = buyerShipping * Multipliers.ebayMultiplier;
  return ebayFeeOnItem + ebayFeeOnShipping;
}

export const calcPaypalFee = (sellPrice, buyerShipping) => {
  return (sellPrice + buyerShipping) * Multipliers.paypalMultiplier + (sellPrice && Multipliers.paypalBase);
}