import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

class MoneyField extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isProfitLabel: PropTypes.bool
  }

  static defaultProps = {
    onChange: () => {},
    isDisabled: false,
    isProfitLabel: false
  }

  render() {
    const { label, value, onChange, isDisabled, isProfitLabel } = this.props;

    const labelClass = classNames(
      { profit : isProfitLabel && value >= 0 },
      { loss : isProfitLabel && value < 0 }
    );

    return (
      <FormGroup>
        <Col sm={4} className={labelClass} componentClass={ControlLabel}>{ label }</Col>
        <Col sm={4}>
          <InputGroup>
            <InputGroup.Addon>$</InputGroup.Addon>
            <FormControl type="number" min="0" value={value} onChange={onChange} disabled={isDisabled}/>
            { this.props.children }
          </InputGroup>
        </Col>
      </FormGroup>
    )
  }
}

export default MoneyField;