import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, InputGroup } from 'react-bootstrap';

class InputButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    noRadius: PropTypes.bool,
  }

  static defaultProps = {
    onClick: () => {}
  }
  
  state = {
    isSelected: false
  }

  handleChange = event => {
    this.setState((prevState, props) => {
      return {isSelected: !prevState.isSelected};
    });
    this.props.onClick(event);
  }

  render() {
    const { label, noRadius, type } = this.props;
    const bsStyle = (type) ? type : 'info';
    const buttonClasses = classNames(
      { active: this.state.isSelected },
      { 'no-radius': noRadius }
    );
    const labelClasses = classNames(
      { badge : this.state.isSelected }
    );
    
    return (
      <InputGroup.Button>
        <Button bsStyle={bsStyle} className={buttonClasses} onClick={this.handleChange}>
          <span className={labelClasses}> {label} </span>
        </Button>
      </InputGroup.Button>
    )
  }
}

export default InputButton;